.container {
    background-color: #f3f6f8;
    margin: var(--spacing-core-5) 0 var(--spacing-core-3);
    padding: var(--spacing-core-2) var(--spacing-core-8) var(--spacing-core-2) var(--spacing-core-4);
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.containerFormVariant {
    border-radius: var(--border-radius-core-m);
    padding: var(--spacing-core-3) var(--spacing-core-8) var(--spacing-core-3) var(--spacing-core-4);
    margin: var(--spacing-core-2) 0;
}

.iconsContainer {
    display: flex;
    align-items: center;
}

.iconButton {
    transition: all 0.2s ease-in-out;
    background: transparent;
    border: none;
    padding: var(--sizing-core-1);
    color: inherit;
    user-select: none;

    &:disabled {
        cursor: not-allowed;
        opacity: 0.2;
    }

    &:not(:disabled):focus-visible,
    &:not(:disabled):hover {
        color: #acacac;
        cursor: pointer;
    }

    > svg {
        outline: none;
        box-shadow: none;
        user-select: none;
    }
}

.icon {
    vertical-align: middle;
}

.counter {
    margin: 0 30px;
}

@media screen and (device-width <= 496px) {
    .container {
        margin: var(--spacing-core-3) 0 var(--spacing-core-1);
    }

    .counter {
        margin: 0 var(--spacing-core-5);
    }
}
